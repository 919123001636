import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements OnDestroy {
  static TYPE_FRANCHISE_TOKEN(TYPE_FRANCHISE_TOKEN: any, token: any) {
    throw new Error('Method not implemented.');
  }
  static AD_USER(AD_USER: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
  private readonly subscription: Subscription = new Subscription();
  private storageSubjectsMap: Map<string, BehaviorSubject<any>>;
  //private storageValues = {};
  private storageValues: { [key: string]: any } = {};

  constructor() {
    this.storageSubjectsMap = new Map<string, BehaviorSubject<any>>();
    this.checkLocalStorageChanges();
  }

  checkLocalStorageChanges() {
    const checkLocalStoreChangesTimer$ = timer(10000, 5000);
    this.subscription.add(
      checkLocalStoreChangesTimer$.subscribe((doCheck) => {
        for (const [key, value] of this.storageSubjectsMap.entries()) {
          const storageItem = localStorage.getItem(key);
          if (this.storageValues[key] !== storageItem) {
            this.storageValues[key] = storageItem;
            value.next(storageItem);
          }
        }
      })
    );
  }

  private getStorageSubject(identifier: string): BehaviorSubject<any> {
    let storageSubject: BehaviorSubject<any> = this.storageSubjectsMap.get(
      identifier
    ) as BehaviorSubject<any>;
    if (!storageSubject) {
      storageSubject = new BehaviorSubject<any>(null);
      this.storageSubjectsMap.set(identifier, storageSubject);
    }
    return storageSubject;
  }

  public getStorageItem(identifier: string): BehaviorSubject<string | null> {
    const storageItem = localStorage.getItem(identifier);
    const storageSubject = this.getStorageSubject(identifier);
    if (this.storageValues[identifier] !== storageItem) {
      this.storageValues[identifier] = storageItem;
      storageSubject.next(storageItem);
    }
    return storageSubject;
  }

  public setStorageItem(identifier: string, object: string): void {
    this.storageValues[identifier] = object;
    localStorage.setItem(identifier, object);
    this.getStorageSubject(identifier).next(object);
  }

  public removeStorageItem(identifier: string): void {
    localStorage.removeItem(identifier);
    this.getStorageSubject(identifier).next(null);
  }

  public clear() {
    localStorage.clear();
    this.storageSubjectsMap.forEach((storageSubject: BehaviorSubject<any>) => {
      storageSubject.next(null);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
